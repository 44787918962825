export const bankName = [
  {
    value: "dbs",
    label: "DBS"
  },
  {
    value: "uob",
    label: "UOB"
  },
  {
    value: "ocbc",
    label: "OCBC"
  },
  {
    value: "maybank",
    label: "MayBank"
  },
  {
    value: "hsbc",
    label: "HSBC"
  },
  {
    value: "rhb",
    label: "RHB"
  },
  {
    value: "citi",
    label: "Citibank"
  },
  {
    value: "cimb",
    label: "CIMB"
  },
  {
    value: "sc",
    label: "Standard Chartered"
  },
  {
    value: "other",
    label: "Other..."
  }
];

export const mapScheme = {
  PAYNOW: "PayNow",
  GRABPAY: "Grab Pay",
  DASH: "Singtel Dash",
  WECHATPAY: "WeChat Pay",
  REMO: "Remo Pay",
  UNIONPAY15: "Union Pay",
  EZLINK: "EZ-Link",
  ACTIVESG: "ActiveSG",
  NETSPAY: "NETS Pay",
  ALIPAY: "Alipay",
  SHOPEEPAY: "ShopeePay",
  ATOME: "Atome"
};

export const mcc = {
  "4784": "Bridge and road fees, toll",
  "4111": "Transportations",
  "5699": "Accessory and Apparel Stores – Miscellaneous",
  "7999": "Recreation services not elsewhere classified",
  "5411": "Groceries & Supermarkets",
  "7298": "Health & Beauty Spas",
  "9399": "Government services not elsewhere classified",
  "5499": "Miscellaneous Food Stores - Convenience Stores, Markets, Speciality Stores",
  "4121": "Taxi & limousines and 4131 Bus Lines",
  "5999": "Miscellaneous & specialty retail stores ",
  "5942": "Book stores",
  "8299": "Schools & education services",
  "8641": "Associations, civic, social & fraternal ",
  "7523": "Automobile parking lots & garages",
  "6011": "Automated cash disbursement",
  "5812": "Eating places & restaurants",
  "5814": "Fast Food Restaurants",
  "4214": "Motor Freight Carriers, Trucking - Local/Long Distance, Moving & Storage Companies/Local Delivery",
  "4814": "Telecommunication Services including but not limited to prepaid phone services and recurring phone services",
  "4900": "Utilities - Electric, Gas, Heating Oil, Sanitary, Water",
  "5462": "Bakeries",
  "4789": "Transportation Services - not elsewhere classified",
  "5311": "Department stores"
};
