import React from "react";
import { Formik, FieldArray } from "formik";
import { withStyles, Hidden } from "@material-ui/core";
import AddressInput from "../../components/AddressInput.formik";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import DropzoneField from "../../components/DropzoneField.formik";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import SGQRKYCSchema from "../../models/SGQRKYC";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Stepper from "@material-ui/core/Stepper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import config from "../../config";
import uuid from "uuid/v1";
import { get, isEmpty } from "lodash-es";
import {
  bankName as bankNameOptions,
  mapScheme as mapScheme2Name,
  mcc as mccOptions
} from "../../models/SGQRKYC/options";
import { postJson } from "../../utils/apiClient";
import JSONTree from "react-json-tree";

/**
 * @typedef OutletFlat
 * @type {Object}
 * @property {string} post_code
 * @property {string} unit_no
 * @property {string} level_no
 * @property {number} outlet_id
 * @property {string} misc
 * @property {string} sgqr_id
 * @property {string} name_on_label
 */

/**
 * @typedef Terminal
 * @type {Object}
 * @property {string} name_on_label
 * @property {boolean} sgqr_id_exist
 * @property {string} sgqr_id
 * @property {string} misc
 * @property {any} [payload_info]
 */

/**
 * @typedef Outlet
 * @type {Object}
 * @property {string} address
 * @property {string} post_code
 * @property {string} level_no
 * @property {string} unit_no
 * @property {Terminal[]} terminals
 */

/**
 *
 * @param {OutletFlat[]} outletsFlat
 * @returns {Outlet[]}
 */
function unflattenOutletTerminals(outletsFlat) {
  /** @type {Outlet[]} */
  let resOutlets = [];

  // TODO: O(n^2), optimize

  for (let outletFlat of outletsFlat) {
    let found = false;

    // try and find one matching outlet
    for (let outlet of resOutlets) {
      if (
        outlet.post_code === outletFlat.post_code &&
        outlet.level_no === outletFlat.level_no &&
        outlet.unit_no === outletFlat.unit_no
      ) {
        outlet.terminals.push({
          key: uuid(),
          name_on_label: outletFlat.name_on_label,
          sgqr_id_exist: !!outletFlat.sgqr_id,
          sgqr_id: outletFlat.sgqr_id || "",
          misc: outletFlat.misc || "",
          payload_info: outletFlat.payload_info
        });
        found = true;
        break;
      }
    }
    // create new outlet if not found
    if (!found) {
      resOutlets.push({
        key: uuid(),
        address: outletFlat.post_code, // we don't really store the full address for now, just show post_code
        post_code: outletFlat.post_code,
        level_no: outletFlat.level_no,
        unit_no: outletFlat.unit_no,
        terminals: [
          {
            // initially with one terminal
            key: uuid(),
            name_on_label: outletFlat.name_on_label,
            sgqr_id_exist: !!outletFlat.sgqr_id, // have sgqr_id => it exists
            sgqr_id: outletFlat.sgqr_id,
            misc: outletFlat.misc,
            payload_info: outletFlat.payload_info
          }
        ]
      });
    }
  }

  return resOutlets;
}

const styles = theme => ({
  mainPaper: {
    padding: theme.spacing.unit * 2
  },
  stepPaper: {
    padding: theme.spacing.unit * 2
  },
  paddedPaper: {
    padding: theme.spacing.unit * 2
  },
  footerButtonRow: {
    padding: theme.spacing.unit * 2
  },
  divider: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  },
  hidden: {
    display: "hidden"
  },
  btnProgressWrapper: {
    position: "relative"
  },
  btnProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

const pageURL = new URL(window.location.href);

const reviewActionOptions = {
  edit: "Edit only",
  approve: "Save and approve",
  reject: "Save and reject"
};

const serviceTypeOptions = {
  "sme.fomopay.net": "FOMO SME App",
  "pos.fomopay.com": "FOMO POS (legacy)",
  "pos.fomopay.net": "FOMO POS",
  "api.pos.fomopay.net": "FOMO POS (API only)"
};

const initialValues = {
  schemes: [
    "PAYNOW",
    "GRABPAY",
    "DASH",
    "WECHATPAY",
    "REMO",
    "UNIONPAY15",
    "EZLINK",
    "ACTIVESG",
    "NETSPAY",
    "ALIPAY",
    "SHOPEEPAY",
    "ATOME"
  ],
  outlets: [
    {
      key: uuid(),
      address: "",
      post_code: "",
      level_no: "",
      unit_no: "",
      terminals: [
        {
          name_on_label: "",
          sgqr_id_exist: false,
          sgqr_id: "",
          misc: "",
          key: uuid()
        }
      ]
    }
  ],
  account_name: "",
  account_number: "",
  agent: pageURL.searchParams.get("agent") || "",
  bank_name: "",
  bank_name_other: "",
  company_register_name: "",
  business_description: "",
  remarks: "",
  company_website: "",
  contact_number: "",
  email_address: "",
  full_name: "",
  mcc: "",
  mcc_other: "",
  merchant_trading_name: "",
  mobile_country: "65",
  mobile_number: "",
  nric_number: "",
  recaptcha_token: "",
  referrer: pageURL.searchParams.get("referer") || pageURL.searchParams.get("referrer") || "",
  registered_address: "",
  uen_number: "",
  files_acra: [],
  files_bank_statement: [],
  files_nric: [],
  files_shop_photo: [],
  files_others: [],
  history_review: "",
  review_remarks: "",
  review_action: "edit",
  reviews: [],
  approvements: [],
  qr_types: [],
  service: ""
};

// const initialValues = SGQRKYCSchema.cast();

const steps = [
  {
    id: "schemes",
    label: "Wallets or Schemes"
  },
  {
    id: "company_info",
    label: "Company Information"
  },
  {
    id: "applicant_info",
    label: "Applicant Information"
  },
  {
    id: "bank_info",
    label: "Bank Account Information"
  },
  {
    id: "outlets",
    label: "Outlets and Counters"
  },
  {
    id: "documents",
    label: "Documents"
  },
  {
    id: "summary",
    label: "Summary"
  }
];

const uploadFile = async file => {
  try {
    if (file.id) {
      return file.id;
    }
    const uaid = localStorage.getItem("uaid");

    const imageUploadAPI = new URL("kyc/public/image/upload", config.apiBase);
    let form_data = new FormData();
    form_data.append("image", file);
    let init = {
      credentials: "same-origin",
      method: "POST",
      headers: {
        Authorization: `Bearer Uaid=${uaid}`
      },
      body: form_data
    };
    let response = await fetch(imageUploadAPI, init);
    if (Math.floor(response.status / 100) === 4) {
      console.warn("Unauthorized session. Please refresh to reauthorize.");
      // window.location.replace(config.AUTH_LOGIN);
      return;
    }
    let result = await response.json();
    console.log(result);
    if (result.result_code !== 0) throw new Error(result.result_message);
    if (result.error_code !== 0) throw new Error(result.error_code);
    let file_id = get(result, "data.file_id");
    if (!file_id) throw new Error("file_id invalid");
    return file_id;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

class SGQRKYC extends React.PureComponent {
  state = {
    dialog: {
      open: false
    },
    activePage: 6,
    submitted: false,
    initialValues: null
  };

  refRecaptcha = null;

  showDialog({
    title = "Alert",
    contentText = "",
    hasSecondaryAction = false,
    open = true,
    onSecondaryAction = null,
    onPrimaryAction = null,
    primaryActionText,
    secondaryActionText
  }) {
    this.setState({
      dialog: {
        open,
        title,
        contentText,
        hasSecondaryAction,
        primaryActionText,
        secondaryActionText
      }
    });
    this.handleDialogPrimaryActionClicked = onPrimaryAction;
    this.handleDialogSecondaryActionClicked = onSecondaryAction;
  }

  handleSwitchPage = nextPage => () => {
    // const activePage = this.state.activePage;
    // TODO: check this page validation
    // console.log("nextPage", nextPage);
    this.setState({
      activePage: nextPage
    });
  };

  handleDialogClose = (source = "ok") => () => {
    this.setState({
      dialog: {
        ...this.state.dialog,
        open: false
      }
    });

    switch (source) {
      case "ok":
        typeof this.handleDialogPrimaryActionClicked === "function" && this.handleDialogPrimaryActionClicked();
        break;
      case "cancel":
        typeof this.handleDialogSecondaryActionClicked === "function" && this.handleDialogSecondaryActionClicked();
        break;
      default:
        console.error("Unknown dialog close event from:", source);
        break;
    }
  };

  fetchKYCDocument = async () => {
    // if(process.env.NODE_ENV !== 'production'){
    //     this.setState({ initialValues: initialValues });
    //     return;
    // }
    const applicationID = parseInt(this.props.match.params.id, 10);
    const kycFetchAPI = new URL("kyc/console/fetch", config.apiBase);
    let kycFetchRes = await postJson(kycFetchAPI, {
      id: applicationID
    });
    let kycFetchBody = await kycFetchRes.json();
    let kycFetchData = kycFetchBody.data;
    console.log("Fetched KYC data: ", kycFetchData);
    const convertFileObj = file => ({
      name: file.file_name,
      id: file.file_id
    });

    // TODO:
    // validate before transforming
    // apply default values
    // get deep values efficiently
    let initValueFetched = {
      ...initialValues,
      ...kycFetchData,
      files_acra: get(kycFetchData, "files.acra", []).map(convertFileObj),
      files_bank_statement: get(kycFetchData, "files.bank_statement", []).map(convertFileObj),
      files_nric: get(kycFetchData, "files.nric", []).map(convertFileObj),
      files_shop_photo: get(kycFetchData, "files.shop_photo", []).map(convertFileObj),
      files_others: get(kycFetchData, "files.others", []).map(convertFileObj),
      mobile_number: kycFetchData.mobile_no || "",
      outlets: unflattenOutletTerminals(kycFetchData.outlets),

      uen_number: kycFetchData.uen || "",
      registered_address: kycFetchData.registered_address || "",
      company_website: kycFetchData.company_website || "",
      company_register_name: kycFetchData.registered_name || "",
      full_name: kycFetchData.application_full_name || "",
      home_address: kycFetchData.application_home_address || "",
      contact_number: kycFetchData.application_contact_number || "",
      nric_number: kycFetchData.application_nric_number || "",
      email_address: kycFetchData.application_email_address || "",
      account_name: kycFetchData.bank_account_name || "",
      account_number: kycFetchData.bank_account_number || "",
      bank_name: kycFetchData.bank_name || "",
      service: kycFetchData.service || ""
    };
    console.log(initValueFetched);
    this.setState({ initialValues: initValueFetched });
  };

  handleSubmit = async (values, { setSubmitting }) => {
    console.log("onSubmit called: ", values);

    try {
      let outlets = values.outlets // [{terminals:[{}]},{terminals:[{}]}]
        .map(({ terminals, ...outlet }) => terminals.map(terminal => ({ ...outlet, ...terminal })))
        .reduce((prev, curr) => [...prev, ...curr], []);

      let ids_acra = await Promise.all(values.files_acra.map(uploadFile));
      let ids_bank_statement = await Promise.all(values.files_bank_statement.map(uploadFile));
      console.log(ids_acra);
      let ids_others = await Promise.all(values.files_others.map(uploadFile));
      let ids_nric = await Promise.all(values.files_nric.map(uploadFile));
      let ids_shop_photo = await Promise.all(values.files_shop_photo.map(uploadFile));
      const applicationID = parseInt(this.props.match.params.id, 10);

      let businessObj = {
        updated_info: {
          application_id: applicationID,
          uen: values.uen_number,
          mobile_country: values.mobile_country,
          mobile_no: values.mobile_number,
          registered_name: values.company_register_name,
          registered_address: values.registered_address,
          merchant_trading_name: values.merchant_trading_name,
          schemes: values.schemes,
          mcc: values.mcc === "OTHR" ? values.mcc_other : values.mcc,
          outlets,
          referer: values.referrer || values.referer,
          agent: values.agent,
          company_website: values.company_website,
          business_description: values.business_description,
          remarks: values.remarks,
          applicant: {
            full_name: values.full_name,
            contact_number: values.contact_number,
            nric_number: values.nric_number,
            email_address: values.email_address
          },
          bank_info: {
            account_name: values.account_name,
            account_number: values.account_number,
            bank_name: values.bank_name === "other" ? values.bank_name_other : values.bank_name
          },
          files: {
            acra: ids_acra,
            bank_statement: ids_bank_statement,
            nric: ids_nric,
            shop_photo: ids_shop_photo,
            others: ids_others
          }
        }
      };
      console.log("Submitting values: ", businessObj);
      // refRecaptcha.execute();
      setSubmitting(true);
      const kycUpdateAPI = new URL("kyc/console/update", config.apiBase);
      let kycUpdateRes = await postJson(kycUpdateAPI, businessObj);
      let kycUpdateData = await kycUpdateRes.json();
      // console.log(kycUpdateData);
      //  TODO: replace fetch implementation
      //  let res = await restClient('CREATE','business',{data: businessObj});
      // if(res.id){
      //   alert("")
      //   resetForm();
      // }

      if (kycUpdateData.result_code !== 0) {
        this.showDialog({
          contentText:
            "Error processing your submission. Please check your input and try again. Reason: " +
            kycUpdateData.result_message
        });
        return;
      }

      if (kycUpdateData.error_code !== 0 && kycUpdateData.error_code !== 1100) {
        this.showDialog({
          contentText:
            "Error processing your submission. Please check your input and try again. Reason: " +
            kycUpdateData.error_message
        });
        return;
      }

      if (["approve", "reject"].includes(values.review_action)) {
        const kycApproveBody = {
          id: values.application_id,
          operation: values.review_action,
          comment: values.review_remarks,
          service: values.service,
          qr_types: values.qr_types
        };
        const kycApproveAPI = new URL("kyc/console/approve", config.apiBase);
        let kycApproveRes = await postJson(kycApproveAPI, kycApproveBody);
        let kycApproveData = await kycApproveRes.json();
        if (kycApproveData.result_code !== 0) {
          this.showDialog({
            contentText:
              "Error processing your submission. Please check your input and try again. Reason: " +
              kycApproveData.result_message
          });
          return;
        }

        if (kycApproveData.error_code !== 0) {
          this.showDialog({
            contentText:
              "Error processing your submission. Please check your input and try again. Reason: " +
              kycApproveData.error_message
          });
          return;
        }
      }

      this.setState({
        submitted: true
      });
      this.showDialog({
        title: "Congratulations!",
        contentText: `Submission successful for UEN ${values.uen_number}.`,
        primaryActionText: "Done",
        secondaryActionText: "Create Another",
        onPrimaryAction: null,
        onSecondaryAction: () => window.location.reload(),
        hasSecondaryAction: true
      });
      // resetForm();
      return;
    } catch (err) {
      console.error(err);
      alert("An error occured: " + err.message);
    } finally {
      setSubmitting(false);
    }
  };

  componentDidMount() {
    this.fetchKYCDocument();
  }

  render = () => (
    <Paper elevation={2} className={this.props.classes.mainPaper}>
      <h1>SGQR Onboarding Information Review</h1>
      <p>Review and edit merchant KYC information.</p>
      {/*
              The benefit of the render prop approach is that you have full access to React's
              state, props, and composition model. Thus there is no need to map outer props
              to values...you can just set the initial values, and if they depend on props / state
              then--boom--you can directly access to props / state.
              The render prop accepts your inner form component, which you can define separately or inline
              totally up to you:
              - `<Formik render={props => <form>...</form>}>`
              - `<Formik component={InnerForm}>`
              - `<Formik>{props => <form>...</form>}</Formik>` (identical to as render, just written differently)
            */}
      {this.state.initialValues ? (
        <Formik
          initialValues={this.state.initialValues}
          validationSchema={SGQRKYCSchema}
          onSubmit={this.handleSubmit}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            submitForm
          }) => (
            <div>
              <div>
                <Hidden smUp>
                  <Stepper nonLinear orientation="vertical" activeStep={this.state.activePage}>
                    {steps.map((elem, idx) => (
                      <Step key={elem.id}>
                        <StepButton onClick={this.handleSwitchPage(idx)}>{elem.label}</StepButton>
                      </Step>
                    ))}
                  </Stepper>
                </Hidden>
                <Hidden xsDown>
                  <Stepper nonLinear alternativeLabel orientation="horizontal" activeStep={this.state.activePage}>
                    {steps.map((elem, idx) => (
                      <Step key={elem.id}>
                        <StepButton onClick={this.handleSwitchPage(idx)}>{elem.label}</StepButton>
                      </Step>
                    ))}
                  </Stepper>
                </Hidden>
              </div>
              <form onSubmit={handleSubmit}>
                {this.state.activePage === 0 && (
                  <Paper elevation={2} className={this.props.classes.stepPaper}>
                    <Grid container spacing={16}>
                      <Grid item xs={12}>
                        <Typography variant="headline" gutterBottom>
                          Step 1 – Wallets or Schemes
                        </Typography>
                        <Typography variant="body1">
                          Please note this whole form takes around 10 minutes to complete.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FieldArray
                          name="schemes"
                          render={arrayHelpers => (
                            <FormControl component="fieldset">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.schemes.includes("PAYNOW")}
                                      onChange={e =>
                                        e.target.checked
                                          ? arrayHelpers.push("PAYNOW")
                                          : arrayHelpers.remove(values.schemes.indexOf("PAYNOW"))
                                      }
                                      value="PAYNOW"
                                    />
                                  }
                                  label="PayNow"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.schemes.includes("GRABPAY")}
                                      onChange={e =>
                                        e.target.checked
                                          ? arrayHelpers.push("GRABPAY")
                                          : arrayHelpers.remove(values.schemes.indexOf("GRABPAY"))
                                      }
                                      value="GRABPAY"
                                    />
                                  }
                                  label="Grab Pay"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.schemes.includes("DASH")}
                                      onChange={e =>
                                        e.target.checked
                                          ? arrayHelpers.push("DASH")
                                          : arrayHelpers.remove(values.schemes.indexOf("DASH"))
                                      }
                                      value="DASH"
                                    />
                                  }
                                  label="Singtel Dash"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.schemes.includes("WECHATPAY")}
                                      onChange={e =>
                                        e.target.checked
                                          ? arrayHelpers.push("WECHATPAY")
                                          : arrayHelpers.remove(values.schemes.indexOf("WECHATPAY"))
                                      }
                                      value="WECHATPAY"
                                    />
                                  }
                                  label="WeChat Pay"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.schemes.includes("REMO")}
                                      onChange={e =>
                                        e.target.checked
                                          ? arrayHelpers.push("REMO")
                                          : arrayHelpers.remove(values.schemes.indexOf("REMO"))
                                      }
                                      value="REMO"
                                    />
                                  }
                                  label="REMO Pay"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.schemes.includes("UNIONPAY15")}
                                      onChange={e =>
                                        e.target.checked
                                          ? arrayHelpers.push("UNIONPAY15")
                                          : arrayHelpers.remove(values.schemes.indexOf("UNIONPAY15"))
                                      }
                                      value="UNIONPAY15"
                                    />
                                  }
                                  label="Union Pay"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.schemes.includes("EZLINK")}
                                      onChange={e =>
                                        e.target.checked
                                          ? arrayHelpers.push("EZLINK")
                                          : arrayHelpers.remove(values.schemes.indexOf("EZLINK"))
                                      }
                                      value="EZLINK"
                                    />
                                  }
                                  label="EZ-Link"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.schemes.includes("ACTIVESG")}
                                      onChange={e =>
                                        e.target.checked
                                          ? arrayHelpers.push("ACTIVESG")
                                          : arrayHelpers.remove(values.schemes.indexOf("ACTIVESG"))
                                      }
                                      value="ACTIVESG"
                                    />
                                  }
                                  label="ActiveSG"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.schemes.includes("NETSPAY")}
                                      onChange={e =>
                                        e.target.checked
                                          ? arrayHelpers.push("NETSPAY")
                                          : arrayHelpers.remove(values.schemes.indexOf("NETSPAY"))
                                      }
                                      value="NETSPAY"
                                    />
                                  }
                                  label="NETS Pay"
                                />
                              </FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.schemes.includes("ALIPAY")}
                                    onChange={e =>
                                      e.target.checked
                                        ? arrayHelpers.push("ALIPAY")
                                        : arrayHelpers.remove(values.schemes.indexOf("ALIPAY"))
                                    }
                                    value="ALIPAY"
                                  />
                                }
                                label="Alipay"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.schemes.includes("SHOPEEPAY")}
                                    onChange={e =>
                                      e.target.checked
                                        ? arrayHelpers.push("SHOPEEPAY")
                                        : arrayHelpers.remove(values.schemes.indexOf("SHOPEEPAY"))
                                    }
                                    value="SHOPEEPAY"
                                  />
                                }
                                label="ShopeePay"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.schemes.includes("ATOME")}
                                    onChange={e =>
                                      e.target.checked
                                        ? arrayHelpers.push("ATOME")
                                        : arrayHelpers.remove(values.schemes.indexOf("ATOME"))
                                    }
                                    value="ATOME"
                                  />
                                }
                                label="Atome"
                              />
                              <FormHelperText>Select the wallets and schemes you signed up.</FormHelperText>
                              {errors.schemes && <FormHelperText error>{errors.schemes}</FormHelperText>}
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid container spacing={16}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="referer"
                            label="Referrer"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.referer}
                            helperText={touched.referer && errors.referer}
                            error={!!(touched.referer && errors.referer)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            id="agent"
                            label="Agent"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.agent}
                            helperText={touched.agent && errors.agent}
                            error={!!(touched.agent && errors.agent)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                )}
                {this.state.activePage === 1 && (
                  <Paper elevation={2} className={this.props.classes.stepPaper}>
                    <Grid container spacing={16}>
                      <Grid item xs={12}>
                        <Typography variant="headline" gutterBottom>
                          Step 2 – Company Information
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="mobile_country"
                          style={{ width: "6ex" }}
                          label="CC"
                          type="tel"
                          autoComplete="tel-country-code"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.mobile_country}
                          helperText={touched.mobile_country && errors.mobile_country}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">+</InputAdornment>
                          }}
                          error={!!(touched.mobile_country && errors.mobile_country)}
                        />
                        &nbsp;&nbsp;
                        <TextField
                          id="mobile_number"
                          label="Mobile Number"
                          style={{ width: "16ex" }}
                          type="tel"
                          autoComplete="tel-national"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.mobile_number}
                          inputProps={{
                            maxLength: 12
                          }}
                          helperText={
                            "You will use this to login. " + ((touched.mobile_number && errors.mobile_number) || "")
                          }
                          error={!!(touched.mobile_number && errors.mobile_number)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="company_register_name"
                          label="Company Registered Name"
                          autoComplete="organization"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.company_register_name}
                          helperText={touched.company_register_name && errors.company_register_name}
                          style={{
                            width: "25ex"
                          }}
                          error={!!(touched.company_register_name && errors.company_register_name)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="merchant_trading_name"
                          label="Trading/Shop Name"
                          autoComplete="organization"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.merchant_trading_name}
                          helperText={touched.merchant_trading_name && errors.merchant_trading_name}
                          error={!!(touched.merchant_trading_name && errors.merchant_trading_name)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="uen_number"
                          label="UEN/ Registration No."
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.uen_number}
                          helperText={touched.uen_number && errors.uen_number}
                          placeholder="Unique Entity Number"
                          error={!!(touched.uen_number && errors.uen_number)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="registered_address"
                          label="Registered Address"
                          multiline
                          fullWidth
                          autoComplete="work street-address"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.registered_address}
                          helperText={touched.registered_address && errors.registered_address}
                          error={!!(touched.registered_address && errors.registered_address)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <FormControl error={!!((touched.mcc && errors.mcc) || (touched.mcc_other && errors.mcc_other))}>
                          <InputLabel>MCC Code</InputLabel>
                          <NativeSelect
                            id="mcc"
                            value={values.mcc}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            input={<Input name="mcc" />}
                          >
                            <option value="" />
                            {Object.entries(mccOptions).map(([mcc, label]) => (
                              <option key={mcc} value={mcc}>
                                {mcc}: {label}
                              </option>
                            ))}
                            <option value="OTHR">Other...</option>
                          </NativeSelect>
                          {values.mcc === "OTHR" && (
                            <TextField
                              id="mcc_other"
                              type="text"
                              label="MCC Code"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.mcc_other}
                              error={!!((touched.mcc && errors.mcc) || (touched.mcc_other && errors.mcc_other))}
                            />
                          )}
                          <FormHelperText>
                            {(touched.mcc && errors.mcc) || (touched.mcc_other && errors.mcc_other)}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="company_website"
                          label="Company Website (optional)"
                          style={{
                            width: "25ex"
                          }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.company_website}
                          autoComplete="url"
                          helperText={touched.company_website && errors.company_website}
                          placeholder="If you have one"
                          error={!!(touched.company_website && errors.company_website)}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          id="business_description"
                          label="Business Description (optional)"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.business_description}
                          helperText={touched.business_description && errors.business_description}
                          placeholder="What your business do"
                          error={!!(touched.business_description && errors.business_description)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="remarks"
                          label="Remarks (optional)"
                          fullWidth
                          multiline
                          rows={3}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.remarks}
                          helperText={touched.remarks && errors.remarks}
                          placeholder="Other remarks you may want to add"
                          error={!!(touched.remarks && errors.remarks)}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                )}
                {this.state.activePage === 2 && (
                  <Paper elevation={2} className={this.props.classes.stepPaper}>
                    <Grid container spacing={16}>
                      <Grid item xs={12}>
                        <Typography variant="headline" gutterBottom>
                          Step 3 – Applicant Information
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="full_name"
                          label="Full Name"
                          autoComplete="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.full_name}
                          helperText={touched.full_name && errors.full_name}
                          placeholder="As per ID"
                          error={!!(touched.full_name && errors.full_name)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="contact_number"
                          label="Contact Number"
                          type="tel"
                          autoComplete="tel"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contact_number}
                          inputProps={{
                            maxLength: 16
                          }}
                          helperText={touched.contact_number && errors.contact_number}
                          error={!!(touched.contact_number && errors.contact_number)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="nric_number"
                          label="NRIC Number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.nric_number}
                          helperText={touched.nric_number && errors.nric_number}
                          error={!!(touched.nric_number && errors.nric_number)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="email_address"
                          label="Email Address"
                          type="email"
                          autoComplete="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email_address}
                          helperText={touched.email_address && errors.email_address}
                          error={!!(touched.email_address && errors.email_address)}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                )}
                {this.state.activePage === 3 && (
                  <Paper elevation={2} className={this.props.classes.stepPaper}>
                    <Grid container spacing={16}>
                      <Grid item xs={12}>
                        <Typography variant="headline" gutterBottom>
                          Step 4 – Bank Account Info
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <FormControl error={!!(touched.bank_name && errors.bank_name)}>
                          <InputLabel>Bank Name</InputLabel>
                          <NativeSelect
                            id="bank_name"
                            value={values.bank_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            input={<Input name="bank_name" />}
                          >
                            <option value="" />
                            {bankNameOptions.map(elem => (
                              <option key={elem.value} value={elem.value}>
                                {elem.label}
                              </option>
                            ))}
                          </NativeSelect>
                          {values.bank_name === "other" && (
                            <TextField
                              id="bank_name_other"
                              label="Bank Name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.bank_name_other}
                              helperText={touched.bank_name_other && errors.bank_name_other}
                              error={!!(touched.bank_name_other && errors.bank_name_other)}
                            />
                          )}
                          <FormHelperText>{touched.bank_name && errors.bank_name}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="account_name"
                          label="Account Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.account_name}
                          helperText={touched.account_name && errors.account_name}
                          error={!!(touched.account_name && errors.account_name)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="account_number"
                          label="Account Number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.account_number}
                          helperText={touched.account_number && errors.account_number}
                          placeholder="Please double check"
                          error={!!(touched.account_number && errors.account_number)}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                )}
                {this.state.activePage === 4 && (
                  <Paper elevation={2} className={this.props.classes.stepPaper}>
                    <Typography variant="headline" gutterBottom>
                      Step 5 – Outlets and Counters
                    </Typography>
                    <FieldArray
                      name="outlets"
                      render={arrayHelpers => (
                        <Grid item xs={12}>
                          {values.outlets.map((elem, idx) => (
                            <AddressInput
                              key={elem.key}
                              id="outlets"
                              label="Outlet"
                              index={idx}
                              setFieldValue={val => setFieldValue(["outlets", idx], val)}
                              onRemoveThis={() => values.outlets.length > 1 && arrayHelpers.remove(idx)}
                              error={get(errors, ["outlets", idx], {})}
                              value={get(values, ["outlets", idx], {})}
                              touched={get(touched, ["outlets", idx], {})}
                            />
                          ))}
                          {typeof errors.outlets === "string" && (
                            <FormHelperText error>{errors.outlets}</FormHelperText>
                          )}
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={() =>
                              arrayHelpers.push({
                                key: uuid(),
                                address: "",
                                post_code: "",
                                level_no: "",
                                unit_no: "",
                                terminals: [
                                  {
                                    name_on_label: "",
                                    sgqr_id_exist: false,
                                    sgqr_id: "",
                                    misc: "",
                                    key: uuid()
                                  }
                                ]
                              })
                            }
                          >
                            Add an outlet
                            <Icon>add</Icon>
                          </Button>
                        </Grid>
                      )}
                    />
                  </Paper>
                )}
                {this.state.activePage === 5 && (
                  <Paper elevation={2} className={this.props.classes.stepPaper}>
                    <Grid container spacing={16}>
                      <Grid item xs={12}>
                        <Typography variant="headline" gutterBottom>
                          Step 6 – Documents
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <DropzoneField
                          value={values.files_acra}
                          label="Company ACRA"
                          key="files_acra"
                          id="files_acra"
                          accept="application/pdf,image/*"
                          errorText={touched.files_acra && errors.files_acra}
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <DropzoneField
                          label="Bank Statement (info page only)"
                          value={values.files_bank_statement}
                          key="files_bank_statement"
                          id="files_bank_statement"
                          accept="application/pdf,image/*"
                          errorText={touched.files_bank_statement && errors.files_bank_statement}
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <DropzoneField
                          value={values.files_nric}
                          label="Director's NRIC (front / back)"
                          key="files_nric"
                          id="files_nric"
                          accept="application/pdf,image/*"
                          errorText={touched.files_nric && errors.files_nric}
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <DropzoneField
                          value={values.files_shop_photo}
                          label="Shop Photo"
                          key="files_shop_photo"
                          id="files_shop_photo"
                          accept="application/pdf,image/*"
                          errorText={errors.files_shop_photo}
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <DropzoneField
                          value={values.files_others}
                          label="Other (Licenses)"
                          key="files_others"
                          id="files_others"
                          accept="application/pdf,image/*"
                          errorText={touched.files_others && errors.files_others}
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                )}
                {this.state.activePage === 6 && (
                  <Paper elevation={2} className={this.props.classes.stepPaper}>
                    <Grid container spacing={16}>
                      <Grid item xs={12}>
                        <Typography variant="headline" gutterBottom>
                          Step 7 – Summary and Submit
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="title" gutterBottom>
                          1. Wallets/ schemes
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subheading">Selected wallets/schemes</Typography>
                        <Typography variant="body1">
                          {values.schemes.map(val => mapScheme2Name[val]).join(",")}
                        </Typography>
                        {errors.schemes && <FormHelperText error>{errors.schemes}</FormHelperText>}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider className={this.props.classes.divider} />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="title" gutterBottom>
                          2. Company Information
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subheading">Trading/Shop Name</Typography>
                        <Typography variant="body1">{values.merchant_trading_name || "(empty)"}</Typography>
                        {errors.merchant_trading_name && (
                          <FormHelperText error>{errors.merchant_trading_name}</FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subheading">Mobile Number</Typography>
                        <Typography variant="body1">
                          +{values.mobile_country || "(empty)"}-{values.mobile_number || "(empty)"}
                        </Typography>
                        {errors.mobile_country && <FormHelperText error>{errors.mobile_country}</FormHelperText>}
                        {errors.mobile_number && <FormHelperText error>{errors.mobile_number}</FormHelperText>}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subheading">Company Registered Name</Typography>
                        <Typography variant="body1">{values.company_register_name || "(empty)"}</Typography>
                        {errors.company_register_name && (
                          <FormHelperText error>{errors.company_register_name}</FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subheading">Company UEN Number</Typography>
                        <Typography variant="body1">{values.uen_number || "(empty)"}</Typography>
                        {errors.uen_number && <FormHelperText error>{errors.uen_number}</FormHelperText>}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subheading">Registered Address</Typography>
                        <Typography variant="body1">{values.registered_address || "(empty)"}</Typography>
                        {errors.registered_address && (
                          <FormHelperText error>{errors.registered_address}</FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subheading">Company Website</Typography>
                        <Typography variant="body1">{values.company_website || "(empty)"}</Typography>
                        {errors.company_website && <FormHelperText error>{errors.company_website}</FormHelperText>}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subheading">Business Description</Typography>
                        <Typography variant="body1">{values.business_description || "(empty)"}</Typography>
                        {errors.business_description && (
                          <FormHelperText error>{errors.business_description}</FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subheading">Remarks</Typography>
                        <Typography variant="body1">{values.remarks || "(empty)"}</Typography>
                        {errors.remarks && <FormHelperText error>{errors.remarks}</FormHelperText>}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subheading">MCC Code</Typography>
                        <Typography variant="body1">
                          {(values.mcc === "OTHR" ? values.mcc_other : values.mcc) || "(empty)"}
                        </Typography>
                        {errors.mcc && <FormHelperText error>{errors.mcc} </FormHelperText>}
                        {errors.mcc_other && <FormHelperText error>{errors.mcc_other} </FormHelperText>}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider className={this.props.classes.divider} />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="title" gutterBottom>
                          3. Applicant Information
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subheading">Full Name</Typography>
                        <Typography variant="body1">{values.full_name || "(empty)"}</Typography>
                        {errors.full_name && <FormHelperText error>{errors.full_name}</FormHelperText>}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subheading">Contact Number</Typography>
                        <Typography variant="body1">{values.contact_number || "(empty)"}</Typography>
                        {errors.contact_number && <FormHelperText error>{errors.contact_number}</FormHelperText>}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subheading">NRIC Number</Typography>
                        <Typography variant="body1">{values.nric_number || "(empty)"}</Typography>
                        {errors.nric_number && <FormHelperText error>{errors.nric_number}</FormHelperText>}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subheading">Email Address</Typography>
                        <Typography variant="body1">{values.email_address || "(empty)"}</Typography>
                        {errors.email_address && <FormHelperText error>{errors.email_address}</FormHelperText>}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider className={this.props.classes.divider} />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="title" gutterBottom>
                          4. Bank Account Information
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subheading">Bank Name</Typography>
                        <Typography variant="body1">
                          {(values.bank_name === "other" ? values.bank_name_other : values.bank_name) || "(empty)"}
                        </Typography>
                        {errors.bank_name && <FormHelperText error>{errors.bank_name}</FormHelperText>}
                        {errors.bank_name_other && <FormHelperText error>{errors.bank_name_other}</FormHelperText>}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subheading">Account Name</Typography>
                        <Typography variant="body1">{values.account_name || "(empty)"}</Typography>
                        {errors.account_name && <FormHelperText error>{errors.account_name}</FormHelperText>}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subheading">Account Number</Typography>
                        <Typography variant="body1">{values.account_number || "(empty)"}</Typography>
                        {errors.account_number && <FormHelperText error>{errors.account_number}</FormHelperText>}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider className={this.props.classes.divider} />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="title" gutterBottom>
                          5. Outlets and Counters
                        </Typography>
                      </Grid>
                      {values.outlets.map((outlet, idx_outlet) => {
                        const outletErrs = get(errors, ["outlets", idx_outlet], {});
                        return (
                          <Grid item xs={12} key={outlet.key}>
                            <Paper elevation={2} className={this.props.classes.paddedPaper}>
                              <Grid container spacing={8}>
                                <Grid item xs={12}>
                                  <Typography variant="subheading">
                                    Outlet
                                    {idx_outlet + 1}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="subheading">Address</Typography>
                                  <Typography variant="body1">{outlet.address || "(empty)"}</Typography>
                                  {outletErrs.address && <FormHelperText error>{outletErrs.address}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="subheading">Postcode</Typography>
                                  <Typography variant="body1">{outlet.post_code || "(empty)"}</Typography>
                                  {outletErrs.post_code && (
                                    <FormHelperText error>{outletErrs.post_code}</FormHelperText>
                                  )}
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="subheading">Level</Typography>
                                  <Typography variant="body1">{outlet.level_no || "(empty)"}</Typography>
                                  {outletErrs.level_no && <FormHelperText error>{outletErrs.level_no}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="subheading">Unit</Typography>
                                  <Typography variant="body1">{outlet.unit_no || "(empty)"}</Typography>
                                  {outletErrs.unit_no && <FormHelperText error>{outletErrs.unit_no}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12}>
                                  {outlet.terminals.map((terminal, idx_terminal) => {
                                    const terminalErrs = get(outletErrs, ["terminals", idx_terminal], {});
                                    return (
                                      <Paper
                                        elevation={2}
                                        className={this.props.classes.paddedPaper}
                                        key={terminal.key}
                                      >
                                        <Grid container spacing={8}>
                                          <Grid item xs={12}>
                                            <Typography variant="subheading">Terminal {idx_terminal + 1}</Typography>
                                          </Grid>
                                          <Grid item xs={12}>
                                            <Typography variant="subheading">Name on Label</Typography>
                                            <Typography variant="body1">
                                              {terminal.name_on_label || "(empty)"}
                                            </Typography>
                                            {terminalErrs.name_on_label && (
                                              <FormHelperText error>{terminalErrs.name_on_label}</FormHelperText>
                                            )}
                                          </Grid>
                                          <Grid item xs={12}>
                                            <Typography variant="subheading">SGQR ID</Typography>
                                            <Typography variant="body1">{terminal.sgqr_id || "(empty)"}</Typography>
                                            {terminalErrs.sgqr_id && (
                                              <FormHelperText error>{terminalErrs.sgqr_id}</FormHelperText>
                                            )}
                                          </Grid>
                                          <Grid item xs={12}>
                                            <Typography variant="subheading">Misc</Typography>
                                            <Typography variant="body1">{terminal.misc || "(empty)"}</Typography>
                                            {terminalErrs.misc && (
                                              <FormHelperText error>{terminalErrs.misc}</FormHelperText>
                                            )}
                                          </Grid>
                                          <Grid item xs={12}>
                                            <Typography variant="subheading">Payload info</Typography>
                                            <JSONTree data={terminal.payload_info} />
                                          </Grid>
                                        </Grid>
                                      </Paper>
                                    );
                                  })}
                                </Grid>
                                {typeof outletErrs.terminals === "string" && (
                                  <FormHelperText error>{outletErrs.terminals}</FormHelperText>
                                )}
                              </Grid>
                            </Paper>
                          </Grid>
                        );
                      })}
                      {typeof errors.outlets === "string" && (
                        <Grid item xs={12}>
                          <FormHelperText error>{errors.outlets}</FormHelperText>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Divider className={this.props.classes.divider} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <FormControl
                        error={!!(touched.service && errors.service)}
                        disabled={values.review_action === "edit"}
                      >
                        <InputLabel>Service Type</InputLabel>
                        <NativeSelect
                          id="service"
                          value={values.service}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          input={<Input name="service" />}
                        >
                          <option value="" />
                          {Object.entries(serviceTypeOptions).map(([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                        </NativeSelect>
                        <FormHelperText>{errors.service || "Service to be activated."}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FieldArray
                        name="qr_types"
                        render={arrayHelpers => (
                          <FormControl component="fieldset" disabled={values.review_action === "edit"}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.qr_types.includes("static")}
                                    onChange={e =>
                                      e.target.checked
                                        ? arrayHelpers.push("static")
                                        : arrayHelpers.remove(values.qr_types.indexOf("static"))
                                    }
                                    value="static"
                                  />
                                }
                                label="Static QR"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.qr_types.includes("dynamic")}
                                    onChange={e =>
                                      e.target.checked
                                        ? arrayHelpers.push("dynamic")
                                        : arrayHelpers.remove(values.qr_types.indexOf("dynamic"))
                                    }
                                    value="dynamic"
                                  />
                                }
                                label="Dynamic QR"
                              />
                            </FormGroup>
                            {errors.qr_types && <FormHelperText error>{errors.qr_types}</FormHelperText>}
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider className={this.props.classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subheading">History Reviews</Typography>
                      <JSONTree data={values.reviews} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subheading">History Approvals</Typography>
                      <JSONTree data={values.approvements} />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="review_remarks"
                        label="Remarks (optional)"
                        fullWidth
                        multiline
                        rows="4"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.review_remarks}
                        helperText={touched.review_remarks && errors.review_remarks}
                        placeholder="Remarks or reasons for your review."
                        error={!!(touched.review_remarks && errors.review_remarks)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <FormControl error={!!(touched.review_action && errors.review_action)}>
                        <InputLabel>Review action</InputLabel>
                        <NativeSelect
                          id="review_action"
                          value={values.review_action}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          input={<Input name="review_action" />}
                        >
                          {Object.entries(reviewActionOptions).map(([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider className={this.props.classes.divider} />
                    </Grid>
                    {!isEmpty(errors) && (
                      <Grid item xs={12}>
                        <Typography variant="body1" style={{ color: "red" }}>
                          Please correct all errors above before submitting.
                        </Typography>
                      </Grid>
                    )}
                  </Paper>
                )}
                <Grid container className={this.props.classes.footerButtonRow}>
                  <Grid item xs={6} sm={4} md={3} lg={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleSwitchPage(this.state.activePage - 1)}
                      disabled={this.state.activePage === 0}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={4} md={3} lg={2} hidden={this.state.activePage === steps.length - 1}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleSwitchPage(this.state.activePage + 1)}
                    >
                      Next
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={4} md={3} lg={2} hidden={this.state.activePage !== steps.length - 1}>
                    <div className={this.props.classes.btnProgressWrapper}>
                      <Button
                        onClick={submitForm}
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting || this.state.submitted}
                      >
                        Submit
                        {isSubmitting && <CircularProgress size={20} className={this.props.classes.btnProgress} />}
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <p>
                      <Typography variant="caption" component="span">
                        If you have any problems filling this form, please contact Simon via{" "}
                        <a href="tel:+6581663966">+65-81663966</a>
                      </Typography>
                    </p>
                  </Grid>
                  {/* <Grid item xs={12}>
                                        <Typography
                                            variant="caption"
                                            component="pre"
                                        >
                                            this.porps:{" "}
                                            {JSON.stringify(
                                                this.props,
                                                null,
                                                4
                                            )}
                                            <br />
                                            formik.values:{" "}
                                            {JSON.stringify(values, null, 4)}
                                            <br />
                                            formik.errors:
                                            {JSON.stringify(errors, null, 4)}
                                        </Typography>
                                    </Grid> */}
                </Grid>
              </form>
            </div>
          )}
        />
      ) : (
        "Loading..."
      )}
      <Dialog
        open={this.state.dialog.open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{this.state.dialog.title || "Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{this.state.dialog.contentText || ""}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {this.state.dialog.hasSecondaryAction && (
            <Button onClick={this.handleDialogClose("cancel")} color="secondary">
              {this.state.dialog.secondaryActionText || "Cancel"}
            </Button>
          )}
          <Button onClick={this.handleDialogClose("ok")} color="primary" variant="raised" autoFocus>
            {this.state.dialog.primaryActionText || "OK"}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default withStyles(styles)(SGQRKYC);
